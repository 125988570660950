import clsx from 'clsx'
// import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import { NavLink } from 'react-router-dom'
import { checkHasPermission } from '../../../helpers'
import { getCurrentShift } from '../../../../app/modules/settings/shifts/core/_requests'
import { useQuery } from 'react-query'
import { useShiftStore } from '../../../helpers'
import { shallow } from 'zustand/shallow'
import { useEffect } from 'react'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser, logout} = useAuth()
  const [currentShift, loadCurrentShift] = useShiftStore(
    (state: any) => [state.currentShift, state.loadCurrentShift],
    shallow
  )
  useEffect(() => {
    if(checkHasPermission('view_employee_shift')){
      loadCurrentShift();
    }
  }, [])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        {/* <Search /> */}
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          {/* <KTIcon iconName='chart-simple' className={btnIconClass} /> */}
        </div>
      </div>
      
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          {/* <KTIcon iconName='element-plus' className={btnIconClass} /> */}
        </div>
        {/* <HeaderNotificationsMenu /> */}
      </div>

      {checkHasPermission('start_end_employee_shift') ? (
      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'> */}
        <div className="position-relative">
          {/* <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' /> */}
          {JSON.stringify(currentShift) !== '{}' && currentShift.end_time == null && (
            <a className={`btn btn-danger btn-sm`} href="/settings/shifts/list/update">End Shift</a>
          )}
          {JSON.stringify(currentShift) !== '{}' && currentShift.end_time !== null && (
            <a className={`btn btn-info btn-sm`} href="/settings/shifts/list/update">Start Shift</a>
          )}
          {JSON.stringify(currentShift) === '{}' && (
            <a className={`btn btn-info btn-sm`} href="/settings/shifts/list/update">Start Shift</a>
          )}
        </div>
      </div>
      ) : null}
      
      {checkHasPermission('check_in_customer') ? (
      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'> */}
        <div className="position-relative">
          {/* <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' /> */}
          <NavLink className="btn btn-primary btn-sm" to="/match-points/list/check-in">New Check In</NavLink>
        </div>
      </div>
      ) : null}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' /> */}
          <div className='symbol symbol-100px px-3 py-2 bg-gray-800 text-white fs-3'>
            {currentUser?.full_name ? currentUser?.full_name[0] : '0'}
          </div>
        </div>
        <HeaderUserMenu />
      </div>

      {/*config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )*/}
    </div>
  )
}

export {Navbar}
