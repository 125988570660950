import {create} from 'zustand';
import { getSettings } from "../../app/modules/settings/expense-types/core/_requests"
import { getAuth } from '../../app/modules/auth';

// const useShiftStore = create((set, get) => ({
//     currentShift: null,
//     loadCurrentShift: async () => {
//       const getRecord = async () => {
//         const data = await getCurrentShift()
//       }
//       const { isLoading: isCurrentShiftDetailsLoading, data: currentShiftDetails } = useQuery([`current-shift-details`], () => getRecord())
//       if(currentShiftDetails){
//         set({ currentShift: currentShiftDetails })
//       }
//     }
// }));
// const useShiftStore = create((set) => ({
//   currentShift: {},
//   loadCurrentShift: async () => {
//     const API_URL = process.env.REACT_APP_THEME_API_URL
//     const auth = getAuth()
//     if (auth && auth.access_token) {
//       const response = await fetch(`${API_URL}/employeeshifts?get_current=1`,{
//         headers: {
//           'Authorization': `Bearer ${auth.access_token}` 
//         }
//       })
//       // try{
//       //   if (response.ok) {
//       //     const data = await response.json();
//       //     console.log("data",data)
//       //     if (data) {
//       //       set({ currentShift: data });
//       //     } else {
//       //       set({ currentShift: {} }); // or some default value
//       //     }
//       //   }else{
//       //     console.error('Failed to fetch data:', response.status);
//       //     set({ currentShift: {} }); // or some default value
//       //   }
//       // }catch(e){
//       //   console.error('Failed to fetch data:', e);
//       //   set({ currentShift: {} }); // or some default value
//       // }
//       // const data = await response.json();

//       set({ currentShift: await response.json() })
//     }
//   },
// }))

const useShiftStore = create((set) => ({
  currentShift: {},
  loadCurrentShift: async () => {
    const data = await fetchCurrentShift();
    set({ currentShift: data });
  },
}));

const fetchCurrentShift = async () => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const auth = getAuth();
  if (auth && auth.access_token) {
    try {
      const response = await fetch(`${API_URL}/employeeshifts?get_current=1`, {
        headers: {
          'Authorization': `Bearer ${auth.access_token}`
        }
      });

      if (response.ok) {
        return await response.json();
      } else {
        console.error("Failed to fetch data:", response.status);
        return {}; // or some default value
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {}; // or some default value
    }
  }
  return {}; // or some default value
};

export {useShiftStore}

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

export function checkHasPermission(permissions: string) {
  if(permissions == ""){
    return true
  }
  const lsValue: string | null = localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || '')
  if(lsValue){
    const parsedlsValue = JSON.parse(lsValue)
    const userPermissions = parsedlsValue.user.permissions[0] || []
    const permissionsArr = permissions.split("|")
    const permissionExists = permissionsArr.some(r=> userPermissions.includes(r))
    if(permissionExists){
      return true
    }
  }
  return false
}

export function checkIsSuper(){
  const lsValue: string | null = localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || '')
  if(lsValue){
    const parsedlsValue = JSON.parse(lsValue)
    const userRoles = parsedlsValue.user.roles || []
    return userRoles.some((el: any) => el.is_super === true);
  }
  return false
}

export async function getExpenseTypes(){
  // const getRecord = async () => {
    
  //   return data
  // }
  // const { isLoading: isLocationSettingsLoading, data: locationSettings } = useQuery([`location-settings-details`], () => getRecord())
  const data = await getSettings()
  console.log("getExpenseTypes", data)
  // if(locationSettings){
  //   return locationSettings
  // }
  // const lsValue: string | null = localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || '')
  // if(lsValue){
  //   const parsedlsValue = JSON.parse(lsValue)
  //   const userLocation = parsedlsValue.user.location || []
  //   if(userLocation && userLocation.expense_types){
  //     return JSON.parse(userLocation.expense_types)
  //   }
  // }
  return []
}


export function getMachineTypes(){
  return [
    {
      key: 'firelink',
      label: 'Firelink'
    },
    {
      key: 'pot-of-gold',
      label: 'Pot O Gold'
    },
    {
      key: 'life-of-luxury',
      label: 'Life of Luxury'
    },
    {
      key: 'chinese-casino',
      label: 'Chinese Casino'
    },
    {
      key: 'fish-table',
      label: 'Fish Table'
    },
    {
      key: 'texas-keno',
      label: 'Texas Keno'
    },
    {
      key: 'other',
      label: 'Other'
    }
  ];
}

export function getDefaultStartingPoints(){
  const lsValue: string | null = localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || '')
  if(lsValue){
    const parsedlsValue = JSON.parse(lsValue)
    const userLocation = parsedlsValue.user.location || []
    if(userLocation && userLocation.starting_match_points){
      return userLocation.starting_match_points.split(",")
    }
  }
  return []
}
